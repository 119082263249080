<template>
  <div class="container">
    <van-nav-bar
      title="技能认证"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>

    <div class="my-header">
      <div style="height: 10px"></div>
      <div class="info-box">
        <div class="logo">
          <img src="@/assets/img/logo.png" />
        </div>
        <div class="content">
          <div class="title">完成技能认证,接单更多哦!</div>
          <div class="txt">
            各专项技能认证通过之后，才能被推送专项服务的订单哦！
          </div>
        </div>
      </div>
    </div>

    <div class="content-box">
      <van-cell center title="车辆服务" @click="toCarAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="carServiceAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="carServiceAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="carServiceAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="carServiceAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>

      <van-cell center title="企业服务" @click="toEnterpriseAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="enterpriseAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="enterpriseAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="enterpriseAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="enterpriseAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>

      <van-cell center title="办证服务" @click="toCetificateAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="certificateAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="certificateAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="certificateAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="certificateAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>

      <van-cell center title="知识产权" @click="toKownledgeAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="kownledgeAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="kownledgeAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="kownledgeAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="kownledgeAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>

      <van-cell center title="装修用工" @click="toHouseAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="houseAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="houseAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="houseAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="houseAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>

      <van-cell center title="财务服务" @click="toAccountAuth">
        <template #right-icon>
          <div class="right-box">
            <div class="right-item" v-if="accountAuth == 3">
              <span class="title3">审核驳回,重新发起</span>
            </div>
            <div class="right-item" v-if="accountAuth == 2">
              <span class="title1">审核通过</span>
            </div>
            <div class="right-item" v-if="accountAuth == 1">
              <span class="title3">认证中</span>
            </div>
            <div class="right-item" v-if="accountAuth == 0">
              <span class="title2">去认证</span>
            </div>
            <van-icon name="arrow" />
          </div>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
import { Toast } from "vant";
export default {
  data() {
    return {
      carServiceAuth: 0,
      enterpriseAuth: 0,
      certificateAuth: 0,
      kownledgeAuth: 0,
      houseAuth: 0,
      accountAuth: 0,
    };
  },
  async beforeMount() {
    await this.getStaffAuth();
  },
  methods: {
    async getStaffAuth() {
      var that  = this;
      //得到用户认证信息
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
      };
      const resp1 = await UserService.getStaffAuth(this, params);
      if (resp1.data.code == 10000) {
        var list = resp1.data.data;
        list.forEach(function(item) {
          if (item.id == 1) {
            that.carServiceAuth = item.checkServiceStatus;
          }
          if (item.id == 33) {
            that.houseAuth = item.checkServiceStatus;
          }
          if (item.id == 32) {
            that.kownledgeAuth = item.checkServiceStatus;
          }
          if (item.id == 31) {
            that.accountAuth = item.checkServiceStatus;
          }
          if (item.id == 30) {
            that.certificateAuth = item.checkServiceStatus;
          }
          if (item.id == 9) {
            that.enterpriseAuth = item.checkServiceStatus;
          }
        });
      } else {
        Toast(resp1.data.msg);
      }
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    toCarAuth: function() {
      this.$router.push("/person/carAuth");
    },
    toEnterpriseAuth: function() {
      this.$router.push("/person/enterpriseAuth");
    },
    toCetificateAuth: function() {
      this.$router.push("/person/certificateAuth");
    },
    toKownledgeAuth: function() {
      this.$router.push("/person/kownledgeAuth");
    },
    toHouseAuth: function() {
      this.$router.push("/person/houseAuth");
    },
    toAccountAuth: function() {
      this.$router.push("/person/accountAuth");
    },
  },
};
</script>

<style scoped>
.my-header {
  width: 100%;
  height: 180px;
  background: url("~@/assets/img/header-bg.png") no-repeat;
  background-size: 100% 100%;
}
.my-header .info-box {
  margin: 20px 20px;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.my-header .info-box .content {
  margin-left: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.info-box .content .title {
  font-size: 14px;
  color: #333;
  font-weight: 800;
}
.info-box .content .txt {
  margin-top: 6px;
  font-size: 13px;
  font-weight: 400;
  color: #777777;
}

.content-box {
  margin: -40px 24px 0px 24px;
  box-shadow: 0px 0px 7px 1px rgba(223, 223, 223, 0.45);
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
  min-height: 200px;
  z-index: 9999;
}
.group-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.group-title {
  font-size: 16px;
  color: #333;
}
.group-des {
  font-size: 12px;
  color: #808080;
  padding-left: 10px;
}
.right-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.right-box .right-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.right-item .title1 {
  color: #f3c301;
  font-size: 14px;
}

.right-item .title2 {
  color: #3092e6;
  font-size: 14px;
}

.right-item .title3 {
  color: #e75a5a;
  font-size: 14px;
}

.van-cell-group__title {
  color: #333;
}
.container {
  background-color: #ffffff;
}
.p-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
}

.p-cell {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
}
.p-item-text {
  font-size: 14px;
  color: #333333;
}
.p-header .cancel {
  font-size: 14px;
  color: #969799;
}
.p-header .ensure {
  font-size: 14px;
  color: #f3c302;
}
.van-picker__confirm {
  color: #f3c302 !important;
  font-size: 14px !important;
}
.van-sidebar {
  width: 90px !important;
}
.van-sidebar-item--select::before {
  background-color: #f3c302 !important;
}
.van-sidebar-item--select {
  background-color: #ffffff !important;
}
.van-sidebar-item {
  padding: 12px 12px !important;
  background-color: #fafafa;
}
.van-tree-select__item--active {
  color: #f3c302 !important;
}
.van-tree-select__item {
  text-indent: 10px !important;
}
.van-picker-column__item {
  font-size: 14px !important;
}
.van-picker__title {
  font-size: 14px !important;
}
.van-picker__confirm {
  color: #f3c302 !important;
}
.space {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
</style>
